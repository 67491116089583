import AuthService from 'services/AuthService';
import { LabelValue } from 'types';
import { COMPANY_TYPES } from 'types/company';
import { CONTACT_POSITION } from 'types/user';

export const CONSTRUCTION_TYPES = [
  {
    label: 'Combustible',
    value: 'Combustible',
  },
  {
    label: 'Non-Combustible',
    value: 'NonCombustible',
  },
];

export const PROJECT_STATUSES = [
  {
    viewLabel: 'Open',
    actionLabel: 'Open',
    value: 'Open',
    tooltip: 'Default status for a new project.',
  },
  {
    viewLabel: 'Closed',
    actionLabel: 'Close',
    value: 'Closed',
    tooltip: 'Use this status if you wish to close an existing project.',
  },
];

export const ASSIGNMENT_OPTIONS = [
  {
    viewLabel: 'Assigned',
    value: 'true',
  },
  {
    viewLabel: 'Unassigned',
    value: 'false',
  },
];

export const CLAIM_STATUSES = {
  open: {
    label: 'Open',
    value: 'Open',
  },
  closed: {
    label: 'Closed',
    value: 'Closed',
  },
};

export const REPORTS_STATUSES = {
  Sent: {
    value: 'Sent',
    label: 'Sent',
    color: '#083A5E',
    background: '#96CEF6',
  },
  WaitingForApproval: {
    value: 'WaitingForApproval',
    label: 'Waiting for Approval',
    color: '#FFFFFF',
    background: '#969EF6',
  },
  ApprovedByRe: {
    value: 'ApprovedByRe',
    label: 'Draft Approved',
    color: '#FFFFFF',
    background: '#969EF6',
  },
  Rejected: {
    value: 'Rejected',
    label: 'Rejected',
    color: '#FFFFFF',
    background: '#FA928D',
  },
  Approved: {
    value: 'Approved',
    label: 'Approved',
    color: '#123B35',
    background: '#88EEAC',
  },
  Draft: {
    value: 'Draft',
    label: 'Draft',
    color: '#FFFFFF',
    background: '#8F92A3',
  },
};

export const INSPECTION_STATUSES = {
  completed: {
    label: 'Completed',
    value: 'Completed',
  },
  inProgress: {
    label: 'In Progress',
    value: 'InProgress',
  },
  upcoming: {
    label: 'Upcoming',
    value: 'Upcoming',
  },
  cancelled: {
    label: 'Cancelled',
    value: 'Cancelled',
  },
};

export const CURRENCIES = [
  {
    label: 'USD - US Dollar',
    value: 'USD',
    currencySymbol: '$',
  },
  {
    label: 'EUR - Euro',
    value: 'EUR',
    currencySymbol: '€',
  },
  {
    label: 'GBP - British Pound',
    value: 'GBP',
    currencySymbol: '£',
  },
  {
    label: 'AUD - Australian Dollar',
    value: 'AUD',
    currencySymbol: 'AUD',
  },
  {
    label: 'CAD - Canadian Dollar',
    value: 'CAD',
    currencySymbol: 'CAD',
  },
  {
    label: 'CHF - Swiss Franc',
    value: 'CHF',
    currencySymbol: '₣',
  },
];

export const PROJECT_INSPECTION_STATUS = [
  {
    status: 'Not Scheduled',
    key: 'NotScheduled',
    color: 'rgba(235, 87, 87, 1)',
  },
  {
    status: 'Scheduled',
    key: 'Scheduled',
    color: 'rgba(242, 201, 76, 1)',
  },
  {
    status: 'Progressing',
    key: 'Progressing',
    color: 'rgba(58, 192, 157, 1)',
  },
  {
    status: 'Completed',
    key: 'Completed',
    color: 'rgba(31, 186, 250, 1)',
  },
  {
    status: 'Closed',
    key: 'Closed',
    color: 'rgba(169,169,169, 1)',
  },
];

export const contactPositions = (principalCompanyId?: string): LabelValue[] => {
  const companyType: COMPANY_TYPES =
    AuthService.getCompanyType(principalCompanyId);

  return [
    {
      label: 'Broker',
      value: CONTACT_POSITION.BROKER,
      brokerCompanyName: 'Broker',
    },
    {
      label: 'Insurance Representative',
      value: CONTACT_POSITION.INSURANCE_REPRESENTATIVE,
      brokerCompanyName: 'Insurance Representative',
    },
    {
      label: 'Risk Manager',
      value: CONTACT_POSITION.RISK_MANAGER,
      brokerCompanyName: 'Client Risk Manager',
    },
    {
      label: 'Developer Owner',
      value: CONTACT_POSITION.DEVELOPER_OWNER,
      brokerCompanyName: 'Developer Owner',
    },
    {
      label: 'Site Contact',
      value: CONTACT_POSITION.SITE_CONTACT,
      brokerCompanyName: 'Property Respondent',
    },
    {
      label: 'Risk Engineer',
      value: CONTACT_POSITION.RISK_ENGINEER,
      brokerCompanyName: 'Risk Engineer',
    },
    {
      label: 'Risk Engineer',
      value: CONTACT_POSITION.EXTERNAL_RISK_ENGINEER,
      brokerCompanyName: 'Risk Engineer',
    },
    {
      label: 'Risk Engineer',
      value: CONTACT_POSITION.INTERNAL_RISK_ENGINEER,
      brokerCompanyName: 'Risk Engineer',
    },
    {
      label: 'Coordinator',
      value: CONTACT_POSITION.RC_COORDINATOR,
      brokerCompanyName: 'Coordinator',
    },
    {
      label: 'Coordinator',
      value: CONTACT_POSITION.IC_COORDINATOR,
      brokerCompanyName: 'Coordinator',
    },
    {
      label: 'Underwriter',
      value: CONTACT_POSITION.UNDERWRITER,
      brokerCompanyName: 'Client Account Handler',
    },
  ].map((position) => {
    const label =
      companyType === COMPANY_TYPES.BROKER
        ? position.brokerCompanyName
        : position.label;

    return {
      value: position.value,
      label,
    };
  });
};

export const newContactPositions = (
  principalCompanyId?: string,
): LabelValue[] => {
  const allowedPositions: CONTACT_POSITION[] = [
    CONTACT_POSITION.BROKER,
    CONTACT_POSITION.INSURANCE_REPRESENTATIVE,
    CONTACT_POSITION.RISK_MANAGER,
    CONTACT_POSITION.DEVELOPER_OWNER,
    CONTACT_POSITION.SITE_CONTACT,
    CONTACT_POSITION.UNDERWRITER,
    CONTACT_POSITION.INTERNAL_RISK_ENGINEER,
  ];
  const positions1 = contactPositions(principalCompanyId);

  const positions = allowedPositions.map(
    (allowedPosition: CONTACT_POSITION) => {
      const position = positions1.find(
        (contactPosition) => contactPosition.value === allowedPosition,
      );

      if (position == null) {
        return {
          label: 'None',
          value: 'None',
        };
      }

      return position;
    },
  );

  return positions;
};

export const newGlobalContactPositions = (
  principalCompanyId?: string,
): LabelValue[] => {
  const allowedPositions: CONTACT_POSITION[] = [
    CONTACT_POSITION.BROKER,
    CONTACT_POSITION.INSURANCE_REPRESENTATIVE,
    CONTACT_POSITION.RISK_MANAGER,
    CONTACT_POSITION.DEVELOPER_OWNER,
    CONTACT_POSITION.SITE_CONTACT,
    CONTACT_POSITION.INTERNAL_RISK_ENGINEER,
  ];

  return allowedPositions.map((allowedPosition: CONTACT_POSITION) => {
    const position = contactPositions(principalCompanyId).find(
      (contactPosition) => contactPosition.value === allowedPosition,
    );

    if (position == null) {
      return {
        label: 'None',
        value: 'None',
      };
    }

    return position;
  });
};

export const BUDGET_SERVICES_LIMIT = 3;

export const BUDGET_SERVICES = {
  projectType: { value: 'ProjectType', amount: 'brPremium' },
  dsu: { value: 'DSU', amount: 'dsuPremium' },
  tria: { value: 'TRIA', amount: 'triaPremium' },
};

export const OP_BUDGET_SERVICES = {
  projectType: { value: 'ProjectType', amount: 'biPremium' },
  tria: { value: 'TRIA', amount: 'triaPremium' },
};

export const BUDGET_TOOLTIPS = {
  budgetsAtLimit:
    'You have already allocated budget for all of your services. If you want to update it, you can edit already allocated budgets.',
  isProjectClosed:
    'The project is closed. You cannot add budget for closed projects.',
};

export const FEE_PROPOSAL_TOOLTIPS = {
  isResourcesAssigned:
    'You cannot create the fee proposal as the resource has been already assigned to the project.',
  noBudgetsAdded:
    'You have no budgets added for the project. Please add the budget before creating the fee proposal.',
  isProjectClosed:
    'The project is closed. You cannot create fee proposal for closed projects.',
  isExpensesAdded:
    'Time and Expenses were already added to the project. You cannot create Fee Proposal.',
};

export const CONTACT_STATUSES = {
  noAccess: { label: 'No Access', value: 'NoAccess' },
  inviteSent: { label: 'Invite Sent', value: 'InviteSent' },
  needApproval: { label: 'Need Approval', value: 'NeedApproval' },
  hasAccess: { label: 'Has Access', value: 'HasAccess' },
  accessRejected: { label: 'Access Rejected', value: 'AccessRejected' },
};

export const SUBSCRIPTION_STATUSES = {
  Active: {
    label: 'Active',
    value: 'Active',
    background: '#88EEAC',
    color: '#123B35',
  },
  Upcoming: {
    label: 'Upcoming',
    value: 'Upcoming',
    background: '#969EF6',
    color: '#fff',
  },
  Expired: {
    label: 'Expired',
    value: 'Expired',
    background: '#8F92A3',
    color: '#fff',
  },
};

export const CONTACT_STATUSES_COLORS = {
  InviteSent: {
    color: '#083A5E',
    background: '#96CEF6',
  },
  HasAccess: {
    color: '#123B35',
    background: '#88EEAC',
  },
  NoAccess: {
    color: 'rgba(255, 255, 255, 0.87)',
    background: '#8F92A3',
  },
  NeedApproval: {
    color: 'rgba(255, 255, 255, 0.87)',
    background: '#969EF6',
  },
  AccessRejected: {
    color: 'rgba(255, 255, 255, 0.87)',
    background: '#FA928D',
  },
};

export const EXPENSE_CATEGORIES = [
  { label: 'Flight', value: 'Flight' },
  { label: 'Hotel', value: 'Hotel' },
  { label: 'Postage', value: 'Postage' },
  { label: 'Taxi', value: 'Taxi' },
  { label: 'Train', value: 'Train' },
  { label: 'Car Hire', value: 'CarHire' },
  { label: 'Fuel', value: 'Fuel' },
  { label: 'Subsistence', value: 'Subsistence' },
  { label: 'General Expense', value: 'GeneralExpense' },
];

export const TIME_EXPENSES_CATEGORIES_RE = {
  expenses: [
    { label: 'Flight', value: 'Flights' },
    { label: 'Car Hire', value: 'CarHire' },
    { label: 'Fuel', value: 'Fuel' },
    { label: 'Hotel', value: 'Hotel' },
    { label: 'Taxi', value: 'Taxi' },
    { label: 'Subsistence', value: 'Subsistence' },
    { label: 'Tolls', value: 'Tolls' },
    { label: 'Mileage', value: 'Mileage' },
    { label: 'Photocopying', value: 'Photocopying' },
    { label: 'Photographs', value: 'Photographs' },
    { label: 'Printing', value: 'Printing' },
    { label: 'Set up files', value: 'SetUpFiles' },
    { label: 'Others', value: 'Others' },
  ],
  time: [
    { label: '3D Rendering', value: 'Rendering3D' },
    { label: 'Arranging Inspection', value: 'ArrangingInspection' },
    { label: 'Check Reports', value: 'CheckReports' },
    { label: 'Correspondence', value: 'Correspondence' },
    { label: 'Drone Inspection', value: 'DroneInspection' },
    { label: 'Drone Survey', value: 'DroneSurvey' },
    { label: 'File Review', value: 'FileReview' },
    { label: 'Generate Reports', value: 'GenerateReports' },
    { label: 'Measured Survey', value: 'MeasuredSurvey' },
    { label: 'Meeting', value: 'Meeting' },
    { label: 'Research Time', value: 'ResearchTime' },
    { label: 'Risk Recommendations', value: 'RiskRecommendations' },
    {
      label: 'Update Risk Recommendations',
      value: 'UpdateRiskRecommendations',
    },
    { label: 'Site Inspection', value: 'SiteInspection' },
    { label: 'Travel Time', value: 'TravelTime' },
  ],
};

export const NOTIFICATION_ENUM = {
  registration: 'RegistrationFormSubmitted',
  companyRegistration: 'RiskCompanyRegistrationFormSubmitted',
  feeProposal: 'FeeProposalSubmitted',
  assignmentReceived: 'NewAssignmentReceived',
  assignmentConfirmed: 'AssignmentConfirmed',
  newCompanyAssignmentReceived: 'NewCompanyAssignmentReceived',
  recommendationRequireApproval: 'DraftInspectionRequiresApproval',
  recommendationAwaitingApproval: 'InspectionWaitingForApproval',
  recommendationAwaitingIssue: 'InspectionAwaitingIssue',
  recommendationRejected: 'InspectionRejected',
  recommendationAmended: 'InspectionAmended',
  recommendationApproved: 'InspectionApproved',
  recommendationAutoApproved: 'InspectionAutoApproved',
  recommendationIssuedSiteContact: 'InspectionIssuedToSC',
  recommendationIssuedOther: 'InspectionIssued',
  recommendationAwaitingAction: 'RisksWaitingForAction',
  recommendationActioned: 'RisksActioned',
  recommendationStatusUpdatedSiteContact: 'RisksStatusUpdatedSC',
  recommendationStatusUpdatedOther: 'RisksStatusUpdated',
  recommendationClosedSiteContact: 'RisksClosedSC',
  recommendationClosedOther: 'RisksClosed',
  budgetReached: 'BudgetThresholdReached',
  contractValueIncreased: 'ContractValueIncreased',
  claimAlert: 'NewInsuranceClaim',
  abridgedReportGenerated: 'AbridgedReportGenerated',
  abridgedReportRequestApproval: 'AbridgedReportRequestApproval',
  abridgedReportApproved: 'AbridgedReportApproved',
  mainReportSentForApproval: 'MainReportSentForApproval',
  mainReportApproved: 'MainReportApproved',
  mainReportRejected: 'MainReportRejected',
  mainReportAvailable: 'MainReportAvailable',
  mainReportAvailableWithoutApproval: 'MainReportAvailableWithoutApproval',
  mainReportPending: 'MainReportPending',
  abridgedReportPending: 'AbridgedReportPending',
  mainReportIssued: 'MainReportIssued',
  inspectionScheduledInLocation: 'InspectionScheduledInLocation',
  passwordExpirationReminder: 'PasswordExpirationReminder',
  filesUploadedToProject: 'FilesUploadedToProject',
  layoutsUploadedToProject: 'LayoutsUploadedToProject',
  filesDeletedFromProject: 'FilesDeletedFromProject',
  projectCanceled: 'ProjectCanceled',
  projectClosed: 'ProjectClosed',
  projectHasNoAssignment: 'ProjectHasNoAssignment',
  projectHasNoAssignedRE: 'ProjectHasNoAssignedRE',
  projectPolicyExpireSoon: 'ProjectPolicyExpireSoon',
  inspectionUpcomingSoon: 'InspectionUpcomingSoon',
  riskInspectionScheduled: 'RiskInspectionScheduled',
  virtualInspectionScheduled: 'VirtualInspectionScheduled',
  inspectionUpdated: 'InspectionUpdated',
  inspectionCanceled: 'InspectionCanceled',
  inspectionCanceledWithReason: 'InspectionCanceledWithReason',
  inspectionScheduleReminder: 'InspectionScheduleReminder',
  projectLocationNotAssigned: 'ProjectLocationNotAssigned',
  riskReviewsUploadedToProject: 'RiskReviewsUploadedToProject',
  projectCanBeReassigned: 'ProjectCanBeReassigned',
  projectReallocation: 'ProjectReallocation',
  inspectionStartingSoon: 'InspectionStartingSoon',
  approvalSettingUpdated: 'ApprovalSettingUpdated',
  missingDocumentsReminder: 'MissingDocumentsReminder',
  missingDocumentsDeadline: 'MissingDocumentsDeadline',
  weatherAlert: 'WeatherAlert',
  furtherActionRiskActioned: 'FurtherActionRiskActioned',
};

export const NOTIFICATION_TYPES: any[] = [
  {
    type: NOTIFICATION_ENUM.recommendationAwaitingIssue,
    subject: 'Recommendations Awaiting Issue',
  },
  {
    type: NOTIFICATION_ENUM.registration,
    subject: 'Registration Form Submitted',
    actionRequired: true,
  },
  {
    type: NOTIFICATION_ENUM.companyRegistration,
    subject: 'Company Registration Form Submitted',
    actionRequired: true,
  },
  {
    type: NOTIFICATION_ENUM.feeProposal,
    subject: 'Fee Proposal Submitted',
  },
  {
    type: NOTIFICATION_ENUM.assignmentReceived,
    subject: 'New Assignment',
    actionRequired: true,
  },
  {
    type: NOTIFICATION_ENUM.assignmentConfirmed,
    subject: 'Assignment Confirmed',
  },
  {
    type: NOTIFICATION_ENUM.newCompanyAssignmentReceived,
    subject: 'New Assignment',
  },
  {
    type: NOTIFICATION_ENUM.recommendationRequireApproval,
    subject: 'Draft Recommendations Require Approval',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationAwaitingApproval,
    subject: 'Recommendations Awaiting Approval',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationRejected,
    subject: 'Recommendations Rejected',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationAmended,
    subject: 'Recommendations Amended',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationApproved,
    subject: 'Recommendations Approved',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationAutoApproved,
    subject: 'Recommendations Auto Approved',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationIssuedSiteContact,
    subject: 'Recommendations Issued',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationIssuedOther,
    subject: 'Recommendations Issued',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationAwaitingAction,
    subject: 'Recommendations Awaiting Action - Reminder',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationActioned,
    subject: 'Recommendations Actioned',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationStatusUpdatedSiteContact,
    subject: 'Recommendations Status Updated',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationStatusUpdatedOther,
    subject: 'Recommendations Status Updated',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationClosedSiteContact,
    subject: 'Recommendations Closed',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.recommendationClosedOther,
    subject: 'Recommendations Closed',
    recommendation: true,
  },
  {
    type: NOTIFICATION_ENUM.budgetReached,
    subject: 'Budget Reached',
  },
  {
    type: NOTIFICATION_ENUM.contractValueIncreased,
    subject: 'Contract Value Increased',
  },
  {
    type: NOTIFICATION_ENUM.claimAlert,
    subject: 'New Insurance Claim',
    isAlert: true,
  },
  {
    type: NOTIFICATION_ENUM.abridgedReportGenerated,
    subject: 'Abridged Recommendation Report Issued',
  },
  {
    type: NOTIFICATION_ENUM.abridgedReportApproved,
    subject: 'Risk Engineering Report Approved',
  },
  {
    type: NOTIFICATION_ENUM.abridgedReportRequestApproval,
    subject: 'Risk Engineering Report – Approval Required',
  },
  {
    type: NOTIFICATION_ENUM.mainReportSentForApproval,
    subject: 'Risk Engineering Report – Approval Required',
  },
  {
    type: NOTIFICATION_ENUM.abridgedReportPending,
    subject: 'Abridged Recommendation Report Reminder',
  },
  {
    type: NOTIFICATION_ENUM.mainReportPending,
    subject: 'Risk Engineering Report Reminder',
  },
  {
    type: NOTIFICATION_ENUM.mainReportApproved,
    subject: 'Risk Engineering Report Approved',
  },
  {
    type: NOTIFICATION_ENUM.mainReportAvailable,
    subject: 'Risk Engineering Report Available',
  },
  {
    type: NOTIFICATION_ENUM.mainReportAvailableWithoutApproval,
    subject: 'Risk Engineering Report Available',
  },
  {
    type: NOTIFICATION_ENUM.mainReportRejected,
    subject: 'Risk Engineering Report - Require Amendments',
  },
  {
    type: NOTIFICATION_ENUM.mainReportIssued,
    subject: 'Risk Engineering Report Issued',
  },
  {
    type: NOTIFICATION_ENUM.inspectionScheduledInLocation,
    subject: 'Resource Assignment Suggestion',
  },
  {
    type: NOTIFICATION_ENUM.passwordExpirationReminder,
    subject: 'Password Expiration Reminder',
  },
  {
    type: NOTIFICATION_ENUM.filesUploadedToProject,
    subject: 'Documents Uploaded',
  },
  {
    type: NOTIFICATION_ENUM.layoutsUploadedToProject,
    subject: 'New Layout Map',
  },
  {
    type: NOTIFICATION_ENUM.filesDeletedFromProject,
    subject: 'Documents Deleted',
  },
  {
    type: NOTIFICATION_ENUM.projectCanceled,
    subject: 'Project Canceled',
  },
  {
    type: NOTIFICATION_ENUM.projectClosed,
    subject: 'File Closed',
  },
  {
    type: NOTIFICATION_ENUM.projectHasNoAssignment,
    subject: 'Project Not Assigned',
  },
  {
    type: NOTIFICATION_ENUM.projectHasNoAssignedRE,
    subject: 'Project Not Assigned',
  },
  {
    type: NOTIFICATION_ENUM.projectPolicyExpireSoon,
    subject: 'Policy Expire',
  },
  {
    type: NOTIFICATION_ENUM.inspectionUpcomingSoon,
    subject: 'Upcoming Inspection',
  },
  {
    type: NOTIFICATION_ENUM.riskInspectionScheduled,
    subject: 'Inspection Scheduled',
  },
  {
    type: NOTIFICATION_ENUM.virtualInspectionScheduled,
    subject: 'Virtual Inspection Scheduled',
  },
  {
    type: NOTIFICATION_ENUM.inspectionUpdated,
    subject: 'Inspection Updated',
  },
  {
    type: NOTIFICATION_ENUM.inspectionCanceled,
    subject: 'Inspection Canceled',
  },
  {
    type: NOTIFICATION_ENUM.inspectionCanceledWithReason,
    subject: 'Inspection Canceled',
  },
  {
    type: NOTIFICATION_ENUM.inspectionScheduleReminder,
    subject: 'Inspection Schedule Reminder',
  },
  {
    type: NOTIFICATION_ENUM.projectLocationNotAssigned,
    subject: 'Location Not Assigned',
  },
  {
    type: NOTIFICATION_ENUM.riskReviewsUploadedToProject,
    subject: 'Risk Reviews Uploaded',
  },
  {
    type: NOTIFICATION_ENUM.projectCanBeReassigned,
    subject: 'Project Can Be Reassigned',
  },
  {
    type: NOTIFICATION_ENUM.projectReallocation,
    subject: 'File Reallocation Request',
  },
  {
    type: NOTIFICATION_ENUM.inspectionStartingSoon,
    subject: 'Inspection starts in less than an hour',
  },
  {
    type: NOTIFICATION_ENUM.approvalSettingUpdated,
    subject: 'Approval Setting Updated',
  },
  {
    type: NOTIFICATION_ENUM.missingDocumentsReminder,
    subject: 'Document Upload - Pending Items',
  },
  {
    type: NOTIFICATION_ENUM.missingDocumentsDeadline,
    subject: 'Document Upload',
  },
  {
    type: NOTIFICATION_ENUM.weatherAlert,
    subject: 'Weather Alert',
  },
  {
    type: NOTIFICATION_ENUM.furtherActionRiskActioned,
    subject: 'Additional Risk Actions',
  },
];

export const NOTIFICATION_SUBJECTS = NOTIFICATION_TYPES.reduce(
  (result, notify) => {
    result[notify.type] = notify.subject;

    return result;
  },
  {},
);

export const AUTOVIEWED_TYPES = NOTIFICATION_TYPES.filter(
  (notify) => !notify.actionRequired,
).map((notify) => notify.type);

export const RECOMMENDATION_TYPES = NOTIFICATION_TYPES.filter(
  (notify) => notify.recommendation,
).map((notify) => notify.type);

export const ALERT_TYPES = NOTIFICATION_TYPES.filter(
  (notify) => notify.isAlert,
).map((notify) => notify.type);

export const RISK_PRIORITIES = [
  { label: 'Immediate', value: 'Immediate' },
  { label: 'Urgent', value: 'Urgent' },
  { label: 'Routine', value: 'Routine' },
  { label: 'Advisory', value: 'Advisory' },
];

const NEW_RISK_TYPES = [
  {
    category: 'Fire',
    types: [
      'Smoking',
      'Hot Work',
      'Electrical',
      'Fire Extinguishers',
      'Fire Department',
      'Heat Sensor',
      'Sprinkler System',
      'VESDA System',
      'ANSUL System',
      'Materials Storage',
      'Vegetation Management',
      'Tar Boilers',
      'Temporary Heating',
      'Materials Protection',
      'Hazardous Storage',
      'Hydrants',
      'Hose Reel',
      'Fire Water Storage',
      'Poor Water Pressure',
    ],
  },
  {
    category: 'Flood',
    types: [
      'Protection Barrier',
      'Demountable Walls',
      'Sand Bags',
      'Diversion Strategy',
      'Poor Drainage',
      'Low Elevation',
      'Natural Spring',
      'Underground Well',
      'Retention Pond',
      'Poor Runoff',
    ],
  },
  {
    category: 'Water Damage',
    types: [
      'Poor Materials',
      'Drainage Systems',
      'Rain Ingress',
      'Jointing Methods',
      'Temporary Protection',
      'Leak Detection',
      'Testing Regime',
      'Lack of Supervsion',
      'Insufficient Training',
      'Prevention Procedures',
      'Shut off Procedure',
      'Valve Location Plan',
      'Lock Out / Protection',
      'Wet Work Permit',
      'Auto Shut Off System',
    ],
  },
  {
    category: 'Impact',
    types: [
      'Vehicle Speed Control',
      'Barrier Protection',
      'Signage',
      'Collision Alarms',
    ],
  },
  {
    category: 'Natural Perils',
    types: [
      'Hurricane Straps',
      'Lack of Protection',
      'Lack of Procedures',
      'Siesmic Bracing',
      'Lighting Protection',
    ],
  },
  {
    category: 'Collapse',
    types: [
      'Temporary Support',
      'Convergence Monitoring',
      'Foundation/Support',
      'Sink Holes',
      'Drainage',
      'Excavation Procedure',
    ],
  },
  {
    category: 'Explosion',
    types: [
      'Strorage of Gas/Acetylene',
      'UXO Survey',
      'Explosive Storage',
      'Flashback Arrestor',
      'Ventilation of Gas',
    ],
  },
  {
    category: 'Health & Safety',
    types: [
      'Induction & Training',
      'Tool Box Talks',
      'PPE',
      'Health & Safety Plan',
      'Slips/Trips',
      'Fall Protection',
      'Water Contamination',
      'Aspestos Contamination',
      'Glazing Standards',
      'Gaurdrail Protection',
      'Handrail Protection',
      'Poor Lighting',
      'Wayfinder Egress',
      'Emergency Escape',
      'PA System',
      'Alarm System',
      'Training',
      'Climate Control',
      'Hazardous Storage',
    ],
  },
  {
    category: 'Quality Procedures',
    types: [
      'Testing Regime',
      'Hot Work Procedure',
      'HAZOP SOP',
      'Prevention SOP',
      'Risk Register',
      'Lack of Audit Trail',
      'Risk Assessment',
      'Quality Management SOP',
      'Evacuation Procedure',
      'Health & Safety Procedure',
      'Lifting Procedure',
      'Hurricane Prepardness SOP',
      'Emergency Shut Down SOP',
      'Traffic Logistics SOP',
      'Commissioning Testing  SOP',
      'Crisis Management SOP',
      'Wet Work Permit',
      'Cyber Security SOP',
    ],
  },
  {
    category: 'Quality Assurance',
    types: [
      'Compliance Monitoring',
      'Punch List Control',
      'Non-Conformance Procedure',
      'Sign-off SOP',
      'Concrete Testing',
      'Third Party Inspection',
      'Quality Assurance SOP',
    ],
  },
  {
    category: 'Security',
    types: [
      'Access Control',
      'CCTV',
      'Security Guards',
      'ID Cards',
      'Alarms',
      'Vehicle Search',
      'Radio Communication',
      'Background Check',
    ],
  },
];

const RISK_TYPES = [
  {
    category: 'Fire',
    types: [
      'Smoking',
      'Hot Work',
      'Electrical',
      'Fire Extinguishers',
      'Fire Department',
      'Fuel Storage',
      'Hydrants',
      'Materials Protection',
      'Physical Protection',
      'Salamander Heater',
      'Tar Boilers',
      'Fire Training',
      'Plinth Protection',
      'Unprotected Escalators',
      'Housekeeping',
    ],
  },
  {
    category: 'Flood',
    types: [
      'Protection Barrier',
      'Demountable Walls',
      'Diversion Strategy',
      'Sand Bags',
      'Flood Storage Pond',
      'Drainage',
      'Unprotected Escalators',
      'Bunding',
      'Datum Level',
    ],
  },
  {
    category: 'Water Damage',
    types: [
      'Audit Trial',
      'Drainage Systems',
      'Insufficient Training',
      'Jointing Methods',
      'Lack of Supervision',
      'Leak Detection',
      'Plastic Pipe',
      'Prevention Plan',
      'Rain Ingress',
      'Shut off Procedure',
      'Temporary Bunding',
      'Temporary Supply',
      'Testing Regime',
      'Valve Location Plan',
    ],
  },
  {
    category: 'Impact',
    types: [
      'Barrier Protection',
      'Collision Alarms',
      'Signage',
      'Vehicle Speed',
    ],
  },
  {
    category: 'Natural Perils',
    types: [
      'Design Standards',
      'Lack of Protection',
      'Hurricane',
      'Flood',
      'Wildfire',
      'Snow/Hail',
      'Sinkholes',
      'Tsunami',
      'Landslide/Avalanche',
      'Volcano',
    ],
  },
  {
    category: 'Collapse',
    types: ['Monitoring', 'Foundation/Support', 'Temporary Support'],
  },
  {
    category: 'Explosion',
    types: [
      'Explosive Storage',
      'Flashback Arrestor',
      'Gas Storage',
      'UXO Survey',
      'Ventilation of Gas',
    ],
  },
  {
    category: 'Health & Safety',
    types: [
      'Health & Safety Plan',
      'Induction',
      'PPE. Tool Box Talks',
      'Housekeeping',
      'Slips/Trips/Falls',
    ],
  },
  {
    category: 'Quality Assurance',
    types: [
      'Compliance Checking',
      'Concrete Testing',
      'Materials Specification',
      'Monitoring & Supervision',
      'Non-Conformance',
      'Snagging Control',
      'Third Party Inspection',
    ],
  },
  {
    category: 'Quality Procedures',
    types: [
      'Audit Trail',
      'Commissioning Testing',
      'Crisis Management',
      'Execution Plans',
      'HAZOP',
      'Health & Safety Plan',
      'Hot Work',
      'Hurricane Plan',
      'Lifting Plans',
      'Management Plans',
      'Prevention Plan',
      'Risk Register',
      'Shut off Procedure',
      'Testing Regime',
      'Traffic Plan',
      'Valve Location Plan',
    ],
  },
  {
    category: 'Security',
    types: [
      'Alarms',
      'CCTV',
      'Fencing',
      'ID Cards',
      'Security Guards',
      'Vehicle Search',
    ],
  },
];

export const joinedRiskTypes = (isOperationalProperty: boolean = false) => {
  if (isOperationalProperty) {
    // join the types property of the new risk types with the existing risk types
    return [...NEW_RISK_TYPES, ...NEW_RISK_TYPES.filter(
      (newRiskType) => isOperationalProperty && !RISK_TYPES.some((riskType) => riskType.category === newRiskType.category),
    )].map((riskType) => {
      const newTypes = riskType.types;
      const existingTypes = RISK_TYPES.find((t) => t.category === riskType.category)?.types || [];
      const types = [...newTypes, ...existingTypes];

      return { ...riskType, types };
    });
  }

  return RISK_TYPES;
};

export const RISK_PRIORITIES_COLORS = {
  Immediate: {
    color: '#FFFFFF',
    background: '#EB5757',
  },
  Urgent: {
    color: '#FFFFFF',
    background: '#3AC09D',
  },
  Routine: {
    color: '#FFFFFF',
    background: '#059FDE',
  },
  Advisory: {
    color: '#5E2108',
    background: '#F2C94C',
  },
};

export const RISK_SCORE_LABELS = {
  veryLow: {
    name: 'Very Low',
    color: '#5E2108',
    background: '#66FF00',
  },
  low: {
    name: 'Low',
    color: '#FFFFFF',
    background: '#03A737',
  },
  moderate: {
    name: 'Moderate',
    color: '#5E2108',
    background: '#FFF200',
  },
  high: {
    name: 'High',
    color: '#FFFFFF',
    background: '#FFCB05',
  },
  veryHigh: {
    name: 'Very High',
    color: '#FFFFFF',
    background: '#FA7000',
  },
  extreme: {
    name: 'Extreme',
    color: '#FFFFFF',
    background: '#FF2620',
  },
  catastrophic: {
    name: 'Catastrophic',
    color: '#FFFFFF',
    background: '#B11226',
  },
};

export const PROJECT_MAX_LENGTHS = {
  name: 50,
  policyNumber: 20,
  typeName: 30,
  number: 19,
  specialInstruction: 300,
  currency: 26,
  closingReason: 50,
  description: 1000,
};

export const PROJECT_MAX_VALUES = {
  percentage: 100,
  number: Number.MAX_SAFE_INTEGER, // 9_007_199_254_740_991
  currency: Number.MAX_SAFE_INTEGER, // was 9_999_999_999_999_999n
};

export const CONTACT_MAX_LENGTHS = {
  name: 45,
  email: 64,
  city: 45,
  state: 45,
  zipCode: 15,
  address: 95,
  companyName: 50,
  designation: 50,
};

export const RISK_STATUSES = {
  draft: { label: 'Draft', value: 'Draft' },
  waitingForApproval: {
    label: 'Waiting for Approval',
    value: 'WaitingForApproval',
  },
  sentToRiskEngineer: {
    label: 'Sent to Risk Engineer',
    value: 'SentToRiskEngineer',
  },
  approved: { label: 'Approved', value: 'Approved' },
  approvedByRe: { label: 'Draft Approved', value: 'ApprovedByRE' },
  rejected: { label: 'Rejected', value: 'Rejected' },
  amended: { label: 'Rejected', value: 'Amended' },
  issued: { label: 'Issued', value: 'Issued' },
  actioned: { label: 'Actioned', value: 'Actioned' },
  inProgress: { label: 'In Progress', value: 'InProgress' },
  furtherAction: { label: 'Further Action', value: 'FurtherAction' },
  closed: { label: 'Closed', value: 'Closed' },
};

const statuses = Object.values(RISK_STATUSES).map(({ value, label }) => [
  value,
  label,
]);

export const RISK_STATUSES_NAMES = Object.fromEntries(statuses);

export const NON_EDITABLE_RISK_STATUSES = [
  RISK_STATUSES.waitingForApproval.value,
  RISK_STATUSES.approved.value,
  RISK_STATUSES.issued.value,
  RISK_STATUSES.actioned.value,
  RISK_STATUSES.inProgress.value,
  RISK_STATUSES.furtherAction.value,
  RISK_STATUSES.closed.value,
];

export const ALREADY_ACTIONED_RISK_STATUSES = [
  RISK_STATUSES.actioned.value,
  RISK_STATUSES.inProgress.value,
  RISK_STATUSES.furtherAction.value,
  RISK_STATUSES.closed.value,
];

export const renderStatusLabelByValue = (value: any, STATUSES: any[]) => {
  const statusItem = Object.values(STATUSES).find(
    (status) => status.value === value,
  );

  if (statusItem) {
    return statusItem.label;
  }

  return 'Status not found';
};

export const getRiskStatusColors = (status: any) => {
  switch (status) {
    case RISK_STATUSES.draft.value:
      return `
        color: #083A5E;
        background-color: var(--info);
      `;
    case RISK_STATUSES.waitingForApproval.value:
      return `
        color: rgba(255, 255, 255, 0.87);
        background-color: #969EF6;
      `;
    case RISK_STATUSES.approved.value:
      return `
        color: rgba(18, 59, 53, 0.87);
        background-color: #88EEAC;
      `;
    case RISK_STATUSES.rejected.value:
    case RISK_STATUSES.amended.value:
      return `
        color: #FFFFFF;
        background-color: var(--error);
      `;
    case RISK_STATUSES.issued.value:
      return `
        color: #5E2108;
        background-color: #FAB095;
      `;
    case RISK_STATUSES.actioned.value:
      return `
        color: #123B35;
        background-color: #96F6EE;
      `;
    case RISK_STATUSES.inProgress.value:
      return `
        color: rgba(255, 255, 255, 0.87);
        background-color: #969EF6;
      `;
    case RISK_STATUSES.furtherAction.value:
      return `
        color: #90540D;
        background-color: #FAE395;
      `;
    case RISK_STATUSES.closed.value:
      return `
        color: rgba(255, 255, 255, 0.87);
        background-color: #8F92A3;
        `;
    case RISK_STATUSES.approvedByRe.value:
      return `
            color: #FFFFF;
            background-color: #969EF6;
            `;
    default:
      return '';
  }
};

export const RISK_STATUSES_LABELS = {
  Issued: {
    color: '#5E2108',
    background: '#FAB095',
  },
  Actioned: {
    color: '#123B35',
    background: '#96F6EE',
  },
  FurtherAction: {
    color: '#90540D',
    background: '#FAE395',
  },
  InProgress: {
    color: '#FFFFFF',
    background: '#969EF6',
  },
  Closed: {
    color: '#FFFFFF',
    background: '#8F92A3',
  },
  Rejected: {
    color: '#FFFFFF',
    background: '#FA928D',
  },
  Amended: {
    color: '#FFFFFF',
    background: '#FA928D',
  },
};

export const ASSIGNMENT_LEVELS = [
  { name: 'Primary', value: 1 },
  { name: 'Secondary', value: 2 },
];

export const VIDEO_FORMATS = 'video/mp4,video/quicktime';

export const VIDEO_FORMATS_OBJ = {
  'video/mp4': ['.mp4', '.mov'],
};

export const RISK_STATUS_BLOCKS =
  'Action can be done only when the recommendations status is “Draft Approved”.';
export const GENERATE_PRELIMINARY_REPORT_KEY = 'generate-preliminary-report';
export const GENERATE_ONLINE_MEETING_REPORT_KEY =
  'generate-online-meeting-report';

export const PROJECT_CLOSED =
  'Project has been closed. No further actions are allowed.';
