import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import Icon from 'components/Icon';

export const Text = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '20px',
  letterSpacing: '0.16px',
  color: theme.palette.text.main,
}));

export const HighlightedText = styled.span`
  font-weight: 600;
`;

export const Subtitle = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '20px',
  letterSpacing: '0.16px',
  textTransform: 'uppercase',
  color: theme.palette.sidebar.selected.icon.background,
  margin: '4px 0 12px',
}));

// export const Subtitle = styled.div`
//   font-weight: 600;
//   font-size: 12px;
//   line-height: 20px;
//   letter-spacing: 0.16px;
//   text-transform: uppercase;
//   color: var(--sidebarSelectedIconBackground);
//   & {
//     margin: 4px 0 12px;
//   }
// `;

export const InfoWrapper = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '16px 20px 25px 20px',
  background: theme.palette.notifications.info.background,
  borderRadius: 4,
  '&': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

// export const InfoWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   padding: 16px 20px 25px 20px;
//   background: var(--notificationInfoBackground);
//   border-radius: 4px;

//   && {
//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
//   }
// `;

export const Contact = styled.div`
  flex-basis: 50%;
  & > div:not(:last-child) {
    margin-bottom: 6px;
  }
`;

export const ButtonOpenProject = styledMui('span')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.light}`,
  height: 48,
  boxSizing: 'border-box',
  background: theme.palette.notifications.info.background,
  width: '100%',
  position: 'relative',
  cursor: 'pointer',
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: 14,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  padding: '13px 29px 13px 19px',
  '&:hover > *': {
    color: theme.palette.sidebar.selected.icon.background,
    path: {
      fill: theme.palette.sidebar.selected.icon.background,
    },
  },
}));

export const StyledIcon = styledMui(Icon)(({ theme, color }) => ({
  width: 24,
  marginRight: 17,
  color: color || theme.palette.sidebar.selected.icon.background,
}));

export const StyledArrow = styled(Icon)`
  margin-left: auto;
`;
