import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProjectsNumberBlock = styled.div`
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  max-width: 260px;
  margin-top: 10px;

  ${({ visible }) => visible && `
    max-height: 500px;
    transition: max-height 0.25s ease-in;
  `}

  ${({ error }) => error && 'padding-bottom: 40px'};
`;

export const Section = styledMui('div')(({ theme, error }) => {
  return {
    background: theme.palette.notifications.dayTitle,
    borderRadius: 6,
    marginBottom: 24,
    padding: '20px 24px 11px 24px',
    ...(error && { paddingBottom: 40 }),
  };
});

export const RadioTitle = styledMui('div')(({ theme }) => {
  return {
    marginBottom: 5,
    fontFamily: 'Avenir',
    fontWeight: 900,
    letterSpacing: '0.16px',
    color: theme.palette.text.main,
  };
});

export const TimeBlock = styled.div`
  margin-bottom: 15px;
`;

export const Text = styledMui('div')(({ theme }) => {
  return {
    marginBottom: 5,
    fontFamily: 'Graphik LC',
    fontWeight: 400,
    fontSize: 14,
    color: theme.palette.text.light,
  };
});

export const HighLightedText = styledMui('span')(({ theme }) => {
  return {
    margin: '0 4px',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.main,
  };
});

export const DateBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  > div {
    width: 48%;
  }
`;
