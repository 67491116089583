import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import { Button, Icon } from 'components';

export const Text = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontSize: 14,
  lineHeight: '20px',
  letterSpacing: '0.16px',
  color: theme.palette.text.main,
}));

export const HighlightedText = styled.span`
  margin: 0 2px;
  font-weight: 600;
`;

export const AgendaLine = styled.div`
  display: flex;
  align-items: center;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoWrapper = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '16px 20px 25px 20px',
  marginTop: 15,
  background: theme.palette.notifications.info.background,
  borderRadius: 4,
}));

export const Contact = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  color: theme.palette.sidebar.selected.icon.background,
  margin: '0 10px',
  transform: 'rotate(180deg)',
}));

export const StyledButton = styledMui(Button)(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontSize: 9,
  lineHeight: '24px',
  color: theme.palette.sidebar.selected.icon.background,
  paddingLeft: 0,
  fontWeight: 'normal',
}));

export const Link = styledMui('a')(({ theme }) => ({
  color: theme.palette.sidebar.selected.icon.background,
}));
