import React, { useState } from "react";

import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";

import Button from "../Button";
import IconButton from "../IconButton";
import { ButtonWrapper } from "./styles";
import { useNextMonthDisabled } from "@mui/x-date-pickers/internals";

const ActionButton = ({
  isIcon = false,
  icon = undefined,
  children = undefined,
  disabled = false,
  dialogContent = "",
  dialogTitle = "",
  dialogForm = undefined,
  onCancel = () => {},
  onClose = () => {},
  onConfirm = () => {},
  onSubmit = () => {},
  confirmButtonTitle = "",
  noCancel = false,
  keepOpen = false,
  ...rest
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  const handleConfirmClick = () => {
    if (onConfirm) {
      onConfirm();
    }

    if (!keepOpen) {
      handleModalClose();
    }
  };

  const handleCancelClick = () => {
    if (onCancel) {
      onCancel();
    }
    handleModalClose();
  };

  const formProps = {
    title: dialogTitle,
    submitButtonTitle: confirmButtonTitle,
    isOpen: true,
    onCancel: handleCancelClick,
    onClose: handleModalClose,
    onSubmit,
  };

  return (
    <>
      {isIcon ? (
        <IconButton
          icon={icon}
          onClick={handleModalOpen}
          disabled={disabled}
          transparent
          {...rest}
        >
          {children}
        </IconButton>
      ) : (
        <ButtonWrapper>
          <Button onClick={handleModalOpen} disabled={disabled} {...rest}>
            {children}
          </Button>
        </ButtonWrapper>
      )}

      {modalOpen && !dialogForm && (
        <ConfirmationDialog
          open
          onClose={handleModalClose}
          onConfirm={handleConfirmClick}
          onCancel={handleCancelClick}
          title={dialogTitle}
          confirmButtonTitle={confirmButtonTitle}
          noCancel={noCancel}
        >
          {dialogContent}
        </ConfirmationDialog>
      )}

      {(modalOpen || keepOpen) &&
        dialogForm &&
        React.cloneElement(dialogForm, formProps)}
    </>
  );
};

export default ActionButton;
