import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { Icon, Button } from 'components';
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog';

export const HeaderPanel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Controls = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;

  > :last-child {
    margin-left: 24px;
  }
`;

export const StyledButton = styledMui(Button)(({ theme }) => {
  return {
    height: 40,
    maxWidth: 121,
    marginLeft: 24,
    color: theme.palette.button.text,
    background: theme.palette.button.color,
    borderRadius: 4,
    fontSize: 14,
  };
});

export const StyledFormDialog = styled(ConfirmationDialog)`
  .MuiDialog-paper {
    max-width: 600px;
  }
`;

export const ReportsRoot = styledMui('div')(({ theme }) => {
  return {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 249,
    margin: '8px 0 0',
    background: theme.palette.sidebar.selected.backgroundColor,
    borderRadius: 4,
  };
});
export const ErrorsRoot = styledMui('div')(({ theme }) => {
  return {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    maxHeight: 195,
    padding: '12px 16px 12px',
    margin: '24px 0 0',
    background: theme.palette.sidebar.selected.backgroundColor,
    borderRadius: 4,
    overflowY: 'auto',
    scrollbarWidth: 'thin',
  };
});

export const StyledSubtext = styledMui('div')(({ theme }) => {
  return {
    fontFamily: 'Graphik LC',
    color: theme.palette.text.light,
    letterSpacing: '0.16px',
    textAlign: 'center',
  };
});

export const StyledSubtextHighlighted = styled(StyledSubtext)`
  display: inline-block;
  font-weight: 500;
`;

export const StyledText = styledMui('div')(({ theme }) => {
  return {
    fontFamily: 'Graphik LC',
    margin: '10px 0',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '18px',
    letterSpacing: '0.16px',
    color: theme.palette.text.main,
  };
});

export const SmallText = styledMui('div')(({ theme }) => {
  return {
    fontFamily: 'Graphik LC',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 10,
    lineHeight: '138.5%',
    color: theme.palette.text.main,
  };
});

export const StyledCrossIcon = styled(Icon)`
  width: 10px;
  height: 10px;
  margin: 4px 8px 0 0;
`;

export const StyledIcon = styledMui(Icon)(({ theme }) => {
  return {
    width: 48,
    height: 48,
    color: theme.palette.success.main,
    svg: {
      width: '100%',
      height: '100%',
    },
  };
});

export const ErrorLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: top;
`;
