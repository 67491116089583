import React from 'react';
import { useField } from 'formik';
import { Tooltip as MuiTooltip } from '@mui/material';

const containerStyles = { width: '100%' };

const FormikField = ({ component, children = null, onChange = (p1, p2 = undefined) => {}, tooltip = null, ...rest }) => {
  const [field, { error, touched, value }, helpers] = useField(rest);

  const handleChange = (event) => {
    const { onChange: fieldOnChange } = field;

    fieldOnChange(event);

    if (onChange) {
      onChange(event.target.value);
    }
  };

  const props = {
    ...field,
    ...helpers,
    ...rest,
    error: touched ? error : undefined,
    value,
    onChange: handleChange,
  };

  if (tooltip) {
    return (
      <MuiTooltip arrow title={tooltip}>
        <div style={containerStyles}>
          {React.createElement(
            component,
            props,
            children,
          )}
        </div>
      </MuiTooltip>
    );
  }
  return (
    React.createElement(
      component,
      props,
      children,
    )
  );
};

export default React.memo(FormikField);
