import React, { useEffect, useState } from "react";

import { FormikField, Select, Input } from "components";
import { CONTACT_MAX_LENGTHS } from "utils/constants";
import { getTheme } from "assets/styles/mui/theme";
import { useTheme } from "@mui/material";
import { COMPANY_TYPES } from "types/company";

const newOption = {
  value: "New",
  label: "Add New +",
};

const designationsOptions = (companyType) => [
  {
    label: "Risk Engineer",
    value: "Engineer",
  },
  {
    label: "Senior Risk Engineer",
    value: "Senior Engineer",
  },
  {
    label: "Surveyor",
    value: "Surveyor",
  },
  {
    label: "Senior Surveyor",
    value: "Senior Surveyor",
  },
  {
    label: "Insurance Coordinator",
    value: "Insurance Coordinator",
    ...(companyType === COMPANY_TYPES.BROKER && {
      label: "Broker Coordinator"
    })
  },
  {
    label: "Risk Coordinator",
    value: "Risk Coordinator",
  },
  {
    label: "Assistant",
    value: "Assistant",
  },
  {
    label: "Administrative",
    value: "Administrative",
  },
];

const DesignationField = ({
  name,
  isView,
  required,
  readOnly,
  multiple,
  disabled,
  isRE,
  theme,
  isCoordinator,
  additionalDesignations,
  withNewDesignation,
  companyType,
  filterDesignations = () => true,
}) => {
  const muiTheme = useTheme();
  const [totalDesignationOptions, setTotalDesignationOptions] = useState(
    withNewDesignation
      ? designationsOptions(companyType).concat([newOption])
      : designationsOptions(companyType)
  );
  useEffect(() => {
    if (additionalDesignations?.constructor === Array) {
      if (withNewDesignation) {
        const newOptions = designationsOptions(companyType)
          .filter(filterDesignations)
          .filter((t) => t.value !== "New")
          .concat(additionalDesignations)
          .concat([newOption]);
        setTotalDesignationOptions(newOptions);
      } else {
        const newOptions = designationsOptions(companyType)
          .filter(filterDesignations)
          .filter((t) => t.value !== "New")
          .concat(additionalDesignations);
        setTotalDesignationOptions(newOptions);
      }
    }
  }, [additionalDesignations]);

  if (isCoordinator) {
    return (
      <FormikField
        required={required}
        component={Select}
        readOnly={readOnly || isView}
        name={name}
        label="Designation"
        options={totalDesignationOptions.filter((t) =>
          t.value.includes("Coordinator")
        )}
        multiple={multiple}
        disabled={disabled}
        theme={getTheme(theme ?? muiTheme.palette.mode)}
      />
    );
  }
  if (!isRE) {
    return (
      <FormikField
        required={required}
        component={Input}
        readOnly={readOnly || isView}
        name={name}
        label="Designation"
        maxLength={CONTACT_MAX_LENGTHS.designation}
        disabled={disabled}
        theme={getTheme(theme ?? muiTheme.palette.mode)}
      />
    );
  }
  return (
    <FormikField
      required={required}
      component={Select}
      readOnly={readOnly || isView}
      name={name}
      label="Designation"
      options={totalDesignationOptions.filter(
        (t) => t.value !== "Insurance Coordinator"
      )}
      multiple={multiple}
      disabled={disabled}
      theme={getTheme(theme ?? muiTheme.palette.mode)}
    />
  );
};

export default DesignationField;
