import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { Select, InputLabel, MenuItem } from '@mui/material';

import Icon from 'components/Icon';

export const StyledMenuItem = styledMui(MenuItem)(({ theme, multiple }) => {
  return {
    minHeight: '40px',
    fontFamily: 'Graphik LC',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0.15,
    color: theme.palette.input.text,
    background: theme.palette.input.color,
    '&:hover': {
      backgroundColor: theme.palette.input.hover.color,
    },
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.input.color,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.MuiListItem-root.Mui-disabled': {
      pointerEvents: 'none',
      backgroundColor: theme.palette.input.hover.color,
      color: theme.palette.input.disabled.text,
    },
    '&.MuiListItem-root.Mui-selected:hover': {
      backgroundColor: theme.palette.input.hover.color,
    },
    '&.addItem': {
      zIndex: 100,
      position: 'sticky',
      bottom: 0,
      color: theme.palette.sidebar.selected.icon.background,
      borderTop: `1px solid ${theme.palette.input.select.topBorder}`,
    },
    ...(!multiple && {
      '&&.MuiListItem-root.Mui-selected': {
        backgroundColor: theme.palette.input.color,
        border: '1px solid #96CEF6',
      },
    }),
  };
});

export const StyledSelect = styledMui(Select)(({ closeIcon, selected, theme, error, readOnly }) => {
  const styles = {
    fontFamily: 'Graphik LC',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0.16,
    color: theme.palette.input.text,
    backgroundColor: theme.palette.input.color,
    borderRadius: 4,
    border: `1px solid ${theme.palette.input.border.color}`,
    ':hover': {
      backgroundColor: theme.palette.input.hover.color,
    },
    '&.Mui-focused': {
      border: `1px solid ${theme.palette.input.focused.border.color}`,
      backgroundColor: theme.palette.input.color,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.input.disabled.color,
      color: theme.palette.input.disabled.text,
    },
    '.MuiSelect-icon': {
      top: 'calc(50% - 11px)',
      ...(readOnly && { display: 'none' }),
    },
    '.MuiIconButton-edgeEnd': {
      marginRight: -8,
    },
    '.MuiFilledInput-input': {
      padding: '22px 16px 6px',
    },
    '.MuiFormLabel-root': {
      fontFamily: 'Graphik LC',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.16,
      color: theme.palette.input.text,
    },
    '.MuiInputLabel-root': {
      fontFamily: 'Graphik LC',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0.16,
      color: theme.palette.input.text,
    },
    '.MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(16px, 13px) scale(0.70)',
    },
  };
  if (error) {
    styles.border = `1px solid ${theme.palette.error.main}`;
  }
  if (readOnly) {
    styles.border = `1px solid ${theme.palette.input.readonly.border}`;
    styles.backgroundColor = 'transparent';
    styles.pointerEvents = 'none';
    styles['& .MuiSelect-icon'] = {
      display: 'none',
    };
  }
  if (selected) {
    styles.color = theme.palette.sidebar.selected.icon.background;
    if (closeIcon) {
      styles['& .MuiSelect-icon'] = {
        display: 'none',
      };
    }
  }
  return styles;
});

export const StyledInputLabel = styledMui(InputLabel)(({ theme, error }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    '> div': {
      marginLeft: 5,
    },
    '&&': {
      fontFamily: 'Graphik LC',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 10,

      lineHeight: '18px',
      letterSpacing: 0.16,
      color: theme.palette.text.light,
      ...(error && { color: theme.palette.error.main }),
    },
    '&&.Mui-disabled': {
      color: theme.palette.border.light,
    },
    '&&.MuiInputLabel-animated': {
      transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, font-size 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
  };
});

export const StyledIcon = styled(Icon)`
  svg > path {
    fill: var(--iconPrimaryColor);
  }
`;
