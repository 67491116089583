import { styled as styledMui } from '@mui/material/styles';

export const Description = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 13,
  lineHeight: '138%',
  letterSpacing: '0.16px',
  color: theme.palette.text.light,
}));
export const RejectionReasonWrapper = styledMui('div')(({ theme }) => ({
  boder: `1px solid ${theme.palette.border.light}`,
  boxSizing: 'border-box',
  borderRadius: 4,
  padding: '15px 20px 18px',
}));

export const RejectionReasonTitle = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 13,
  lineHeight: '23px',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.main,
}));

export const RejectionReasonText = styledMui('span')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 13,
  lineHeight: '23px',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.main,
  opacity: 0.9,
}));
