import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import Icon from 'components/Icon';

export const Text = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '20px',
  letterSpacing: 0.16,
  color: theme.palette.text.primary,
}));

export const HighlightedText = styled.span`
  font-weight: 600;
`;

export const HighlightedEmail = styledMui('span')(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.navbar.tab.active,
}));

export const InfoWrapper = styledMui('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '16px 20px 25px 20px',
  background: theme.palette.notifications.info.background,
  borderRadius: 4,
  '&': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export const Contact = styled.div`
  flex-basis: 50%;
  & > div:not(:last-child) {
    margin-bottom: 6px;
  }
`;

export const ButtonOpenProject = styledMui('span')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.navbar.tab.border}`,
  height: 48,
  boxSizing: 'border-box',
  background: theme.palette.notifications.info.background,
  width: '100%',
  position: 'relative',
  cursor: 'pointer',
  borderRadius: 4,
  '&': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 14,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: '13px 29px 13px 19px',
  },
  div: {
    paddingTop: 2,
  },
  path: {
    fill: theme.palette.icon.primary.color,
  },
  '&:hover > *': {
    color: theme.palette.navbar.tab.active,
    path: {
      fill: theme.palette.navbar.tab.active,
    },
  },
}));

export const StyledIcon = styledMui(Icon)(({ color, theme }) => ({
  width: 24,
  marginRight: 17,
  path: {
    fill: color || theme.palette.navbar.tab.active,
  },
}));

export const StyledArrow = styled(Icon)`
  margin-left: auto;
`;
