import React from "react";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import {
  FormControl,
  FilledInput,
  FormHelperText,
  InputAdornment,
} from "@mui/material";

import IconButton from 'components/buttons/IconButton';

export const StyledInput = styledMui(FilledInput)(({ theme, regularColor, multiline, noIcon,error }) => {
  const styles= {
    borderRadius: 4,
    border: `1px solid ${theme.palette.input.search.border.color}`,
    backgroundColor: regularColor ? theme.palette.input.color : theme.palette.input.search.color,
    ':hover': {
      backgroundColor: theme.palette.input.search.hover.background,
    },
    '&&.Mui-focused': {
      border: `1px solid #96CEF6`,
    },
    '&&.Mui-disabled': {
      backgroundColor: theme.palette.input.search.disabled.color,
    },
    '& .MuiInputBase-input': {
      height: 40,
      maxHeight: 40,
      padding: '1px 12px 0',
      ...(multiline && {
        maxHeight: 120,
        height: 70,
      }),
      ...(!noIcon && {
        paddingLeft: 40,
      }),
    },
    '&&.MuiFilledInput-adornedEnd': {
      paddingRight: 0,
    },
    '.MuiFilledInput-input::-webkit-outer-spin-button, .MuiFilledInput-input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    ...(error && {
      border: `1px solid ${theme.palette.error.main}`,
    }),
    ...(multiline && {
      marginTop: 8,
      paddingTop: 10,
      height: 100,
    }),
  }
  return styles;
});

export const StartIcon = styledMui(InputAdornment)(({ theme }) => ({
  position: 'absolute',
  left: 6,
  top: '50%',
  minWidth: 30,
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  'svg > path': {
    fill: theme.palette.input.search.icon.color,
  },
}));

export const StyledFormHelperText = styledMui(FormHelperText)(({ theme }) => ({
  position: 'absolute',
  bottom: -24,
  left: 0,
  color: theme.palette.error.main,
}));

export const StyledFormControl = styledMui(FormControl)(({ borderless, fullWidth, theme }) => {
  const borderlessStyles = {
      marginLeft: -12,
      '.MuiFilledInput-root': {
        backgroundColor: theme.palette.input.search.borderless.color,
        border: '1px solid transparent',
        ':hover': {
          backgroundColor: theme.palette.input.search.borderless.color,
        },
        ':active': {
          backgroundColor: theme.palette.input.search.borderless.color,
        },
        ':focus': {
          backgroundColor: theme.palette.input.search.borderless.color,
        },
      },
      ['.MuiFilledInput-root' + '.Mui-focused']: {
        border: '1px solid transparent',
        backgroundColor: theme.palette.input.search.borderless.color,
      },
  };
  return {
    maxWidth: fullWidth ? '100%' : 513,
    ...(borderless && borderlessStyles),
  };
});

export const StyledIconButton = styled(IconButton)`
  min-width: 30px;
  min-height: 30px;
  margin-right: 8px;
  background: transparent;
`;
