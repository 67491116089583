import ProjectService from 'projects/api/ProjectService';

const CLEAN_CURRENT_PROJECT = 'CLEAN_CURRENT_PROJECT';
const GET_PROJECT_DATA = 'GET_PROJECT_DATA';
const GET_PROJECT_SC_DATA = 'GET_PROJECT_SC_DATA';
const GET_PROJECTS_LIST = 'GET_PROJECTS_LIST';
const PROJECTS_LIST_SORT_BY = 'PROJECTS_LIST_SORT_BY';
const GET_PARENT_COMPANIES = 'GET_PARENT_COMPANIES';
const GET_PROJECT_TYPES = 'GET_PROJECT_TYPES';
const GET_PROJECT_BUSINESS_TYPES = 'GET_PROJECT_BUSINESS_TYPES';
const SET_PROJECT = 'SET_PROJECT';

export const getProjectData = (id) => ({
  type: GET_PROJECT_DATA,
  apiCall: () => ProjectService.getProjectData(id),
});

export const getProjectForSC = (id) => ({
  type: GET_PROJECT_SC_DATA,
  apiCall: () => ProjectService.getProjectForSC(id),
});

export const setProject = (projectName, projectStatus) => ({
  type: SET_PROJECT,
  projectName,
  projectStatus,
});

export const cleanCurrentProject = () => ({
  type: CLEAN_CURRENT_PROJECT,
});

export const getProjectsList = (params) => (dispatch) => dispatch({
  type: GET_PROJECTS_LIST,
  apiCall: () => ProjectService.getProjectsList(params),
});

export const setProjectsSortBy = (sortBy) => ({
  type: PROJECTS_LIST_SORT_BY,
  sortBy,
});

export const getParentCompanies = () => (dispatch) => {
  return dispatch({
    type: GET_PARENT_COMPANIES,
    apiCall: () => ProjectService.getParentCompanies(),
  });
};

export const getProjectTypes = () => (dispatch) => {
  return dispatch({
    type: GET_PROJECT_TYPES,
    apiCall: () => ProjectService.getProjectTypes(),
  });
};

export const getProjectBusinessTypes = () => (dispatch) => {
  return dispatch({
    type: GET_PROJECT_BUSINESS_TYPES,
    apiCall: () => ProjectService.getProjectBusinessTypes(),
  });
};

const initialState = {
  current: {},
  sortBy: null,
  projectsList: {
  },
  projectSCData: {},
};

export default (state = initialState, action) => {
  const { response } = action;

  switch (action.type) {
    case PROJECTS_LIST_SORT_BY: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }

    case GET_PROJECT_DATA: {
      return {
        ...state,
        current: response,
      };
    }

    case GET_PROJECT_SC_DATA: {
      return {
        ...state,
        projectSCData: response,
      };
    }

    case SET_PROJECT: {
      return {
        ...state,
        current: {
          ...state.current,
          name: action.projectName,
          status: action.projectStatus,
        },
      };
    }

    case CLEAN_CURRENT_PROJECT: {
      return {
        ...state,
        current: {},
      };
    }

    case GET_PROJECTS_LIST: {
      return {
        ...state,
        projectsList: {
          ...state.projectsList,
          ...response,
        },
      };
    }

    default:
      return state;
  }
};
