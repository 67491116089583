import React, { useState, useEffect } from 'react';

import { Table, ActionButton, NoContent } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import * as SubscribersActions from 'subscriptions/store/subscriptions.reducer';
import { current } from 'subscriptions/store/subscriptions.selectors';
import { showNotifySuccess } from 'services/toaster';
import { Root, HeaderPanel, ControlsWrapper, ButtonWrapper } from './styles';
import MyCompanyService from '../../api/MyCompanyService';
import LocationForm from './LocationForm';
import Controls from './Controls';
import { PACKAGES_IDS } from '../../../subscriptions/constants';
import { contactPositions } from 'utils/constants';
import { CONTACT_POSITION } from 'types/user';

const columns = [
  {
    width: '20%', key: 'name', title: 'Name', noSort: true,
  },
  {
    width: '35%', key: 'uwAmount', title: '', noSort: true,
    render: ({ uwAmount }) =>{
      const uwName = contactPositions().find((contact) => contact.value === CONTACT_POSITION.UNDERWRITER).label;
      return `${uwName}s: ${uwAmount}`},
  }, {
    width: '35%', key: 'engineerAmount', title: '', noSort: true,
    render: ({ engineerAmount }) => `Risk Engineers: ${engineerAmount}`,
  },
  {
    width: '10%', key: 'controls', title: '', align: 'right', noSort: true, minWidth: '85px',
  }];

const maxLocation = (subPackage) => {
  switch (subPackage) {
    case PACKAGES_IDS.Global:
      return 3;
    case PACKAGES_IDS.Pro:
      return 2;
    default:
      return 1;
  }
};

const canAddLocations = (subPackge, currAmount) => {
  const max = maxLocation(subPackge);
  return currAmount < max;
};

const Locations = () => {
  const dispatch = useDispatch();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { subscriptionInfo = {} } = useSelector(current);
  const canAdd = canAddLocations(subscriptionInfo?.package, locations.length);

  function getCurrentSubscription() {
    dispatch(SubscribersActions.getCurrentSubscription());
  }

  useEffect(() => {
    getCurrentSubscription();
  }, []);

  const toggleShowWarning = (value = false) => {
    setShowWarning(value);
  };

  const loadLocations = async () => {
    const { data } = await MyCompanyService.getInsuranceCompanyLocations();
    setLocations(data.data);
  };

  useEffect(() => {
    loadLocations();
  }, []);

  const addLocation = async (formValues) => {
    const response = await MyCompanyService.createInsuranceCompanyLocation(formValues);
    if (response.fieldErrors) return response;
    loadLocations();
    const successMessage = 'Location has been created';
    showNotifySuccess(successMessage);
    return response;
  };

  function renderControls(location) {
    return (
      <Controls
        location={location}
        onSuccess={loadLocations}
            />
    );
  }

  function renderNoContent() {
    return (
      <NoContent
        title="There are no contacts yet."
        descr="Click &quot;New Team Member&quot; to add a new contact."
        customIcon="contacts"
            />
    );
  }

  return (
    <Root>
      <HeaderPanel>
        <ControlsWrapper>
          <ButtonWrapper>
            <ActionButton
              text="New Location"
              color="primary"
              variant="contained"
              small
              dialogTitle="New Location"
              dialogForm={(
                <LocationForm />
                            )}
              onSubmit={addLocation}
              confirmButtonTitle="Save"
              disabled={!canAdd}
                        />
          </ButtonWrapper>
        </ControlsWrapper>
      </HeaderPanel>
      <Table
        items={locations}
        columns={columns}
        onNoContent={renderNoContent}
        onRenderControls={renderControls}
            />
    </Root>
  );
};

export default Locations;
