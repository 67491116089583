export enum RiskScoreType {
  Base500 = "Base500",
  Base1000 = "Base1000",
  OperationalPropertyInspection = "OperationalPropertyInspection",
  OperationalPropertyReportNotes = "OperationalPropertyReportNotes",
  OperationalPropertySOP = "OperationalPropertySOP",
  OperationalPropertyConstructionData = "OperationalPropertyConstructionData",
  OperationalPropertyTotal = "OperationalPropertyTotal",
}

export interface GetRiskScoreNameParams {
  riskScore: number;
  riskScoreMax: number;
  isOperationalProperty: boolean;
  riskScoreType?: RiskScoreType;
  source?: string;
}

export interface RiskScoreLegend {
  [key: string]: {
    name: string;
    color: string;
  };
}

export const LEGEND = (
  maxScore: number,
  isOperationalProperty: boolean
): RiskScoreLegend => {
  if (maxScore === 500 && !isOperationalProperty) {
    return {
      veryLow: {
        name: "Very Low",
        color: "#66FF00",
      },
      low: {
        name: "Low",
        color: "#03A737",
      },
      moderate: {
        name: "Moderate",
        color: "#FFF200",
      },
      high: {
        name: "High",
        color: "#FFCB05",
      },
      veryHigh: {
        name: "Very High",
        color: "#FA7000",
      },
      extreme: {
        name: "Extreme",
        color: "#FF2620",
      },
    };
  }

  return {
    veryLow: {
      name: "Very Low",
      color: "#66FF00",
    },
    low: {
      name: "Low",
      color: "#03A737",
    },
    moderate: {
      name: "Moderate",
      color: "#FFF200",
    },
    high: {
      name: "High",
      color: "#FFCB05",
    },
    veryHigh: {
      name: "Very High",
      color: "#FA7000",
    },
    extreme: {
      name: "Extreme",
      color: "#FF2620",
    },
    catastrophic: {
      name: "Catastrophic",
      color: "#b11226",
    },
  };
};

export const getRiskScoreMaxBasedOnType = (
  riskScoreType: RiskScoreType
): number => {
  switch (riskScoreType) {
    case RiskScoreType.Base500:
      return 500;
    case RiskScoreType.OperationalPropertyReportNotes:
      return 700;
    case RiskScoreType.OperationalPropertyInspection:
      return 250;
    case RiskScoreType.OperationalPropertySOP:
      return 200;
    case RiskScoreType.OperationalPropertyConstructionData:
      return 500;
    case RiskScoreType.Base1000:
    case RiskScoreType.OperationalPropertyTotal:
    default:
      return 1000;
  }
};

export const getRiskScoreNamesBasedOnType = (
  riskScore: number,
  riskScoreType: RiskScoreType
): string => {
  if (riskScoreType === RiskScoreType.Base500) {
    switch (true) {
      case riskScore <= 50:
        return "veryLow";
      case riskScore >= 51 && riskScore <= 150:
        return "low";
      case riskScore >= 151 && riskScore <= 250:
        return "moderate";
      case riskScore >= 251 && riskScore <= 350:
        return "high";
      case riskScore >= 351 && riskScore <= 450:
        return "veryHigh";
      case riskScore >= 451:
        return "extreme";
      default:
        return "";
    }
  }
  if (riskScoreType === RiskScoreType.OperationalPropertyConstructionData) {
    //     Very Low	0	71.43
    // Low	71.44	142.86
    // Moderate	142.87	214.29
    // High	214.30	285.71
    // Very High	285.72	357.14
    // Catastrophic	428.58	500.00
    switch (true) {
      case riskScore <= 71.43:
        return "veryLow";
      case riskScore >= 71.44 && riskScore <= 142.86:
        return "low";
      case riskScore >= 142.87 && riskScore <= 214.29:
        return "moderate";
      case riskScore >= 214.3 && riskScore <= 285.71:
        return "high";
      case riskScore >= 285.72 && riskScore <= 357.14:
        return "veryHigh";
      case riskScore >= 357.15 && riskScore <= 428.57:
        return "extreme";
      case riskScore >= 428.58:
        return "catastrophic";
      default:
        return "";
    }
  }
  if (riskScoreType === RiskScoreType.OperationalPropertySOP) {
    //     Very Low	0	28.57
    // Low	28.58	57.14
    // Moderate	57.15	85.71
    // High	85.72	114.29
    // Very High	114.30	142.86
    // Extreme	142.87	171.43
    // Catastrophic	171.44	200.00
    switch (true) {
      case riskScore <= 28.57:
        return "veryLow";
      case riskScore >= 28.58 && riskScore <= 57.14:
        return "low";
      case riskScore >= 57.15 && riskScore <= 85.71:
        return "moderate";
      case riskScore >= 85.72 && riskScore <= 114.29:
        return "high";
      case riskScore >= 114.3 && riskScore <= 142.86:
        return "veryHigh";
      case riskScore >= 142.87 && riskScore <= 171.43:
        return "extreme";
      case riskScore >= 171.44:
        return "catastrophic";
      default:
        return "";
    }
  }
  if (riskScoreType === RiskScoreType.OperationalPropertyTotal) {
    //   Very Low	0	142.86
    // Low	142.87	285.71
    // Moderate	285.72	428.57
    // High	428.58	571.43
    // Very High	571.44	714.29
    // Extreme	714.30	857.14
    // Catastrophic	857.15	1000.00
    switch (true) {
      case riskScore <= 142.86:
        return "veryLow";
      case riskScore >= 142.87 && riskScore <= 285.71:
        return "low";
      case riskScore >= 285.72 && riskScore <= 428.57:
        return "moderate";
      case riskScore >= 428.58 && riskScore <= 571.43:
        return "high";
      case riskScore >= 571.44 && riskScore <= 714.29:
        return "veryHigh";
      case riskScore >= 714.3 && riskScore <= 857.14:
        return "extreme";
      case riskScore >= 857.15:
        return "catastrophic";
      default:
        return "";
    }
  }
  switch (true) {
    case riskScore <= 100:
      return "veryLow";
    case riskScore >= 101 && riskScore <= 200:
      return "low";
    case riskScore >= 201 && riskScore <= 300:
      return "moderate";
    case riskScore >= 301 && riskScore <= 400:
      return "high";
    case riskScore >= 401 && riskScore <= 500:
      return "veryHigh";
    case riskScore >= 501 && riskScore <= 700:
      return "extreme";
    default:
      return "catastrophic";
  }
};

export const getRiskScoreName = ({
  riskScore,
  riskScoreMax,
  isOperationalProperty,
  riskScoreType,
}: GetRiskScoreNameParams): string => {
  if (riskScoreType !== undefined) {
    const type =
      isOperationalProperty && riskScoreType === RiskScoreType.Base1000
        ? RiskScoreType.OperationalPropertyTotal
        : riskScoreType;
    const riskScoreName = getRiskScoreNamesBasedOnType(riskScore, type);
    return riskScoreName;
  }
  if (riskScoreMax === 500 && !isOperationalProperty) {
    switch (true) {
      case riskScore <= 50:
        return "veryLow";
      case riskScore >= 51 && riskScore <= 150:
        return "low";
      case riskScore >= 151 && riskScore <= 250:
        return "moderate";
      case riskScore >= 251 && riskScore <= 350:
        return "high";
      case riskScore >= 351 && riskScore <= 450:
        return "veryHigh";
      case riskScore >= 451:
        return "extreme";
      default:
        return "";
    }
  }
  if (isOperationalProperty && riskScoreMax === 500) {
    switch (true) {
      case riskScore <= 71.43:
        return "veryLow";
      case riskScore >= 71.44 && riskScore <= 142.86:
        return "low";
      case riskScore >= 142.87 && riskScore <= 214.29:
        return "moderate";
      case riskScore >= 214.3 && riskScore <= 285.71:
        return "high";
      case riskScore >= 285.72 && riskScore <= 357.14:
        return "veryHigh";
      case riskScore >= 357.15 && riskScore <= 428.57:
        return "extreme";
      case riskScore >= 428.58:
        return "catastrophic";
      default:
        return "";
    }
  }
  switch (true) {
    case riskScore <= 100:
      return "veryLow";
    case riskScore >= 101 && riskScore <= 200:
      return "low";
    case riskScore >= 201 && riskScore <= 300:
      return "moderate";
    case riskScore >= 301 && riskScore <= 400:
      return "high";
    case riskScore >= 401 && riskScore <= 500:
      return "veryHigh";
    case riskScore >= 501 && riskScore <= 700:
      return "extreme";
    default:
      return "catastrophic";
  }
};
