import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';

import { Icon } from 'components';

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  color: theme.palette.navbar.tab.active,
}));

export const ItemRoot = styledMui('div')(({ theme }) => ({
  flex: '1 0',
  cursor: 'pointer',
  height: 110,
  maxHeight: 110,
  background: theme.palette.notifications.background,
  padding: '13px 24px',
  borderBottom: `1px solid ${theme.palette.notifications.item.borderBottom}`,

  ':last-child': {
    borderBottom: '1px solid transparent',
  },

  ':hover': {
    opacity: 0.8,
  },
}));

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Subject = styledMui('div')(({ theme }) => ({
  display: 'flex',
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 13,
  lineHeight: '150%',
  color: theme.palette.navbar.tab.active,
  '> div': {
    marginLeft: 9,
  },
}));

export const Time = styledMui('div')(({ theme }) => ({
  fontFamily: 'Graphik LC',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 11,
  lineHeight: '150%',
  textAlign: 'right',
  color: theme.palette.navbar.tab.active,
  opacity: 0.5,
}));
