import { styled as styledMui } from '@mui/material/styles';

export const StyledStatus = styledMui('div')(({ theme, status }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontFamily: 'Graphik LC',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.text.main,
    '& svg path': {
      fill: status === 'accepted' ? theme.palette.success.main : theme.palette.error.main,
    },
  };
});
